<template>

    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <sub-title>이벤트</sub-title>
                <div class="event_main">
                    <div class="main_panel">
                        <div class="notice">
                            <div class="event_list">
                                <router-link :to="{path:'/event_detail/' + item.id}" tag="div" class="titleimg" v-for="(item,index) in eventList" :key="index">
                                    <img :src="item.titleimg" alt="이벤트이미지">
                                    <span style="color: #959595;padding: 10px">{{item.title}}</span>
                                </router-link>

                            </div>
                        </div>

                    </div>
                </div>

                <div>
<!--                    <pagination :page-index="pageNum"-->
<!--                                :total="total"-->
<!--                                :page-size="pageSize"-->
<!--                                @change="pageChange"-->
<!--                                v-if="eventList.length > 0"></pagination>-->
                </div>

                <!--          <sub-title>이벤트</sub-title>-->
                <!--          <div class="board_list">-->
                <!--            <div class="event_list">-->
                <!--              <router-link  :to="{path:'/event_detail/' + item.id}" tag="div" class="item" v-for="item in eventList">-->
                <!--                <div class="img_box" style="min-height: 400px">-->
                <!--                    <img   v-if="null != item.eventImg" :src="item.eventImg" alt="이벤트이미지">-->
                <!--                  <div v-if="null != item.content" v-html="item.content" style="box-sizing: border-box;padding: 20px;cursor: pointer">-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                <h3 style="cursor: pointer" :style="{color:item.titleColor}">-->
                <!--                  {{item.title}}-->
                <!--                </h3>-->
                <!--              </router-link>-->

                <!--            </div>-->
                <!--페이지-->

                <!--          </div>-->
            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>

    </div>


</template>

<script>
    import TopbarComp from "../../components/TopbarComp";
    import RightBarComp from "../../components/RightBarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
    import Pagination from "../../components/pagenation/Pagination";
    import FootComp from "../../components/FootComp";
    import SubTitle from "../../components/SubTitle";
    import sportsConst from "../../common/sportsConst";
    import {getEvent} from "../../network/userRequest";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import {postionMixin} from "../../common/mixin";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";

    export default {
        name: "Event",
        mixins: [postionMixin],
        components: {
            RightButtonsComp,
            UserInfoComp,
            SportsBetCartComp,
            SportsLeftBarComp,
            SubTitle,
            FootComp, Pagination, LeisureLeftComp, LeftBarComp, RightBarBannerComp, RightBarComp, TopbarComp
        },
        data() {
            return {
                eventList: [],
                pageNum: 1,
                pageSize: 100,
                total: 1,
                sportsConst,
                clickNumber: 0,
                position: "이벤트",
            }
        },
        methods: {
            initEvent() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getEvent(this.pageNum, this.pageSize).then(res => {
                    if (res.data.success) {
                        this.eventList = res.data.data
                        this.total = res.data.total
                        this.eventList.map(item => {
                            if (item.id == this.clickNumber) {
                                this.$set(item, 'contentShow', true)
                            } else {
                                this.$set(item, 'contentShow', false)
                            }
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })
            },
            showContent(id) {
                if (id === this.clickNumber) {
                    this.clickNumber = 0
                } else {
                    this.clickNumber = id;
                }
                this.eventList.map(item => {
                    if (item.id == id) {
                        this.$set(item, 'contentShow', !item.contentShow)
                    }

                })
            },
            pageChange(page) {
                this.pageNum = page
                this.initEvent()
            },
        },
        created() {
            this.clickNumber = this.$route.query.id
            this.initEvent()
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");
    @import url("../../assets/css/notice.css");

    .event_list {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
    }

    .event_list .titleimg {
        width: 24%;
        margin: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .event_list .titleimg img {
        width: 100%;
    }

    @media screen and (max-width: 1024px) {
        .event_list {
            justify-content: center!important;
        }
        .event_list .titleimg {
            width: 100% !important;
            padding: 5px;
        }

    }
</style>